















































































import 'videojs-contrib-hls';
import { Component, Vue } from 'vue-property-decorator';
import {
    getCourseVideo,
    pushRecordSave,
    pushUserPlayVideoSave,
    pushVideoSave
} from '@/api/courseWork/myCourse/courseVideo.ts';
import myFooter from '@/components/Footer.vue';
import VideoFooter from '@/components/VideoFooter.vue';
// import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
// import 'videojs-contrib-hls';
@Component({
    components: {
        myFooter,
        VideoFooter
    // videoPlayer
    }
})
export default class CourseVideo extends Vue {
  // api参数
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase,no-undef
      semester_id: -1,
      // eslint-disable-next-line @typescript-eslint/camelcase,no-undef
      crs_id: -1
  };

  playerOptions = {
      playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
      autoplay: false, // 如果为true,浏览器准备好时开始回放。
      muted: false, // 默认情况下将会消除任何音频。
      loop: false, // 是否视频一结束就重新开始。
      preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      language: 'zh-CN',
      aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      sources: [{
          type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: '' // url地址
      }],
      poster: '', // 封面地址
      notSupportedMessage: '视频正在加载，请耐心等待', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
      controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
      }
  };

  // 路由数据
  routerData = {
      teacherName: '',
      crsName: '',
      imageUrl: ''

  };

  chapList = [{
      flag: true,
      title: '',
      id: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      sec_list: [{
      // eslint-disable-next-line @typescript-eslint/camelcase
          sec_flag: true,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sec_id: 0,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sec_video: '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_locked: true
      }]
  }];

  openArray = [0, 0]; // 播放记录 第一个是章 第二个是节
  activeName = '0'; // 默认展开上次播放章
  chapterTitle = ''; // 页面标题
  currentTime = 0; // 当前播放时间
  totalTime = 0; // 视频总时长
  recordSaveTimer = 0;
  videoSaveTimer = 0;
  userSaveTimer = 0;
  UserPlayVideo = 1;
  uonlyType = 0;
  player: any; // 视频本体
  uonly = '';

  created() {
      this.getData();
  }

  playerReadied(player: any) {
      player.currentTime(this.currentTime);
  }

  // 方法
  getData() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.crs_id = parseInt(this.$route.query.crsId as string);
      // eslint-disable-next-line @typescript-eslint/camelcase
      const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
      if (checksem !== null) {
          this.data.semester_id = parseInt(checksem.id);
      } else {
          this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      }
      this.routerData.teacherName = this.$route.query.teacherName as string;
      this.routerData.crsName = this.$route.query.crsName as string;
      this.routerData.imageUrl = this.$route.query.imageUrl as string;

      getCourseVideo(this.data).then((res: any) => {
          if (res.code === 200) {
              const data = res.data;
              this.chapList = data.chap_list;
              this.openArray = data.open_array;// 上次播放章节
              this.activeName = data.open_array[0].toString();// 默认展开章节
              this.chapList[this.openArray[0]].flag = false;
              this.chapterTitle = this.chapList[this.openArray[0]].title;
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_flag = true;
              // 解决ref 报错问题
              this.player = this.$refs.player;
              // this.player.video.src = this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_video;
              this.playerOptions.sources[0].src = this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_video;
              this.currentTime = res.data.initial_time;
              this.uonly = res.data.uonly;
              this.$forceUpdate();
          } else {
              this.$message.error(res.error);
          }
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 章改变
  changeChapter(index: number) {
      if (this.openArray[0] === index) {
          return;
      }
      this.chapList[this.openArray[0]].flag = false;
      this.chapList[index].flag = true;
  }

  // 节改变
  changeSection(index: number, subscript: number) {
      if (this.openArray[0] === index && this.openArray[1] === subscript) {
          return;
      }
      this.recordSave();
      this.chapterTitle = this.chapList[this.openArray[0]].title;
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_flag = false;
      this.openArray[0] = index;
      this.openArray[1] = subscript;
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_flag = true;
      this.chapterTitle = this.chapList[this.openArray[0]].title;
      this.chapList = { ...this.chapList };
      this.currentTime = 0;
      this.playerOptions.sources[0].src = this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_video;
      clearInterval(this.recordSaveTimer);
      clearInterval(this.videoSaveTimer);
      clearInterval(this.userSaveTimer);
      this.player.player.pause();
      this.currentTime = 0;
      this.UserPlayVideo = 1;
      console.log('节发生变化');
      this.getUserVideoOynly();
  }

  // 渲染时自动调用1转换01
  courseTitleValue(index: number) {
      index++;
      return index;
      // return index >= 10 ? index.toString() : '0' + index;
  }

  // 保存学习进度
  recordSave() {
      // console.log(this.currentTime);
      this.currentTime = parseInt(this.player.player.cache_.currentTime);
      this.totalTime = parseInt(this.player.player.cache_.duration);
      const recordSaveData = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          semester_id: this.data.semester_id, // 学期id
          // eslint-disable-next-line @typescript-eslint/camelcase
          crs_id: this.data.crs_id, // 课程id
          // eslint-disable-next-line @typescript-eslint/camelcase
          chap_id: this.chapList[this.openArray[0]].id, // 章id
          // eslint-disable-next-line @typescript-eslint/camelcase
          sec_id: this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_id, // 节id
          // eslint-disable-next-line @typescript-eslint/camelcase
          current_time: this.currentTime, // 当前播放时间
          // eslint-disable-next-line @typescript-eslint/camelcase
          total_time: this.totalTime, // 视频总长
          type: 1
      };
      const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
      const currentsemID = parseInt(localStorage.getItem('semesterId') as string);
      if (checksem !== null) {
          if (checksem.id !== currentsemID) {
              recordSaveData.type = 0;
          }
      }
      pushRecordSave(recordSaveData).then((res: any) => {
          if (res.array.length !== 0) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.chapList[this.openArray[0]].sec_list[this.openArray[1]].is_locked = false;
              this.chapList[this.openArray[0]].flag = true;
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_flag = false;

              this.playerOptions.sources[0].src = this.chapList[res.array[0]].sec_list[res.array[1]].sec_video;
              this.chapList[res.array[0]].flag = false;
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.chapList[res.array[0]].sec_list[res.array[1]].sec_flag = true;
              this.chapterTitle = this.chapList[res.array[0]].title;
              this.activeName = res.array[0].toString();
              this.openArray = res.array;
              this.player.player.play();
              this.currentTime = 0;
          }
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 保存视频进度
  videoSave() {
      this.currentTime = parseInt(this.player.player.cache_.currentTime);
      this.totalTime = parseInt(this.player.player.cache_.duration);
      const videoSaveData = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          semester_id: this.data.semester_id, // 学期id
          // eslint-disable-next-line @typescript-eslint/camelcase
          crs_id: this.data.crs_id, // 课程id
          // eslint-disable-next-line @typescript-eslint/camelcase
          chap_id: this.chapList[this.openArray[0]].id, // 章id
          // eslint-disable-next-line @typescript-eslint/camelcase
          sec_id: this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_id, // 节id
          // eslint-disable-next-line @typescript-eslint/camelcase
          play_time: this.currentTime, // 当前播放时间
          type: 1,
          uonly: this.uonly,
          user_play_time: this.UserPlayVideo
      };
      const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
      const currentsemID = parseInt(localStorage.getItem('semesterId') as string);
      if (checksem !== null) {
          if (checksem.id !== currentsemID) {
              videoSaveData.type = 0;
          }
      }
      pushVideoSave(videoSaveData).then((res: any) => {
          console.log(res);
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 开始播放
  videoPlay() {
      console.log('开始播放');
      console.log(this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_id);
      setTimeout(() => {
          this.currentTime = parseInt(this.player.player.cache_.currentTime);
          this.totalTime = Math.floor(this.player.player.cache_.duration);
      }, 1000);
      if (this.recordSaveTimer === 0) {
          this.recordSaveTimer = setInterval(() => {
              this.recordSave(); // 保存学习进度
          }, 60000);
          this.videoSaveTimer = setInterval(() => {
              this.videoSave(); // 保存视频进度
          }, 30000);
      }
      this.userSaveTimer = setInterval(() => {
          if (this.uonlyType === 1) {
              this.getUserVideoOynly();
              this.uonlyType = 0;
          }
          this.UserPlayVideo++; // 保存用户停留页面时长
      }, 1000);
  }

  // 暂停播放
  videoPause() {
      clearInterval(this.recordSaveTimer);
      clearInterval(this.videoSaveTimer);
      clearInterval(this.userSaveTimer);
      this.recordSaveTimer = 0;
      this.videoSaveTimer = 0;
      this.userSaveTimer = 0;
      console.log('暂停播放');
  }

  // 播放时定期触发
  videoPlaying() {
      if ((parseInt(this.player.player.cache_.currentTime) - this.currentTime) >= 30) {
          this.videoSave();
          this.recordSave();
          this.UserPlayVideo++; // 保存用户停留页面时长
      }
  }

  // 结束时 报错时触发
  videoEnded() {
      this.recordSave();
      this.videoSave();
      this.UserPlayVideo = 0;
      this.uonlyType = 1;
      console.log('结束时触发');
  }

  // 返回上一页
  upPage() {
      this.recordSave();
      this.$router.push('/courseWork');
      clearInterval(this.recordSaveTimer);
      clearInterval(this.videoSaveTimer);
      clearInterval(this.userSaveTimer);
  }

  // 路由守卫
  beforeRouteLeave() {
      this.recordSave();
      clearInterval(this.recordSaveTimer);
      clearInterval(this.videoSaveTimer);
      clearInterval(this.userSaveTimer);
  }

  // 获取oynly
  getUserVideoOynly() {
      const postData = {
          semester_id: this.data.semester_id,
          crs_id: this.data.crs_id,
          chap_id: this.chapList[this.openArray[0]].id,
          sec_id: this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_id
      };
      pushUserPlayVideoSave(postData).then((res: any) => {
          this.uonly = res.data.uonly;
      });
  }
}
