import { get, post } from '@/utils/http.js';

/*
function:获取课程视频数据
author:ysl
time:2021/03/16
*/

export function getCourseVideo(data: any) {
    return get('/study/course/detail', data);
}

/*
function:保存学习进度
author:ysl
time:2021/03/16
*/

export function pushRecordSave(data: any) {
    return post('/study/course/record/save', data);
}

/*
function:保存视频进度
author:ysl
time:2021/03/16
*/

export function pushVideoSave(data: any) {
    return post('/study/course/video/save', data);
}

/*
function:保存页面时长记录
author:YSK
time:2022/07/16
*/

export function pushUserPlayVideoSave(data: any) {
    return post('/study/user/vldeoPlay/save', data);
}
